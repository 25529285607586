<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>违禁字管理</span>
            </a-space>
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.keywords" style="width: 200px" placeholder="违禁关键字"></a-input>
                    <a-input v-model="form.replaces" style="width: 200px" placeholder="替换字符"></a-input>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="detailShow = true;mode = 1">新增违禁字</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange" 
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="action" slot-scope="text,record">
                    <span><a @click=edit(record)>编辑</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
        <a-drawer :title="mode==1?'新增违禁字':'编辑违禁字'" :width="720" :visible="detailShow" :body-style="{ paddingBottom: '60px' }" @close="detailClose">
            <div class="drawer-content">
                <span>违禁字信息</span>
                <a-divider></a-divider>
                <a-row>
                    <a-form-model :model="addForm" :rules="rules">
                    <a-col :span="12">
                        <a-form-model-item label="违禁关键字" prop="keywords">
                            <a-input style="width: 240px" v-model="addForm.keywords"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="替换字符" prop="replaces">
                            <a-input style="width: 240px" v-model="addForm.replaces"></a-input>
                        </a-form-model-item>
                    </a-col>
                    </a-form-model>
                </a-row>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="addConfirm" type="primary" :style="{ marginRight: '8px' }">
                    确认
                </a-button>
                <a-button @click="detailClose"> 关闭 </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import {getForbiddenList, addForbidden, findForbidden, updateForbidden, deleteForbidden} from "@/api/basic/forbidden"
import { rules, columns } from "./depend/config";
export default {
    data() {
        return {
            form: {
                keywords: '',
                replaces: ''
            },
            addForm: {
                keywords: '',
                replaces: ''
            },
            mode: 1, //1新增 2编辑
            activeId: undefined,
            activeName: 0,
            rules: rules,
            columns: columns,
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            //详情
            detailShow: false,
            //批量删除
            selectedRowKeys: [],
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let obj = Object.assign(this.form, {pageNum: this.pagination.current,size: this.pagination.pageSize})
            getForbiddenList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form = {
                keywords: '',
                replaces: ''
            },
            this.getData()
        },
        handlerChange() {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        detailClose() {
            this.addForm = {
                name: '',
                tel: '',
                type: undefined,
                status: undefined,
                weight: 0,
            }
            this.detailShow = false
        },
        //添加电话
        addConfirm() {
            if(this.mode == 1) {
                addForbidden(this.addForm).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else {
                let obj = Object.assign(this.addForm, {id: this.activeId})
                updateForbidden(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        //编辑
        edit(val) {
            this.mode = 2;
            findForbidden({prohibitedKeywordsId: val.id}).then(res => {
                let data = res.data;
                this.addForm = {
                    keywords: data.keywords,
                    replaces: data.replaces,
                },
                this.activeId = data.id
            })
            this.detailShow = true;
        },
        //删除
        del(val) {
            this.$confirm({
                title: "是否删除该违禁字？",
                icon:'delete',
                onOk:async()=>{
                    let res = await deleteForbidden({prohibitedKeywordsIds: [val.id]})
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                },
            })
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的违禁字？",
				icon:'delete',
				onOk:async()=>{
                        let res = await deleteForbidden({prohibitedKeywordsIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">
.drawer-content {
    padding: 16px;
}
</style>