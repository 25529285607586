export const columns = [
	{
		title: "违禁关键字",
		width: "33%",
		dataIndex: "keywords",
	},
	{
		title: "替换字符",
		width: "33%",
		dataIndex: "replaces",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "240",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const rules = {
	replaces: [{ required: true, message: "请输入替换的字符", trigger: "blur" }],
	keywords: [{ required: true, message: "请输入违禁字", trigger: "blur" }],
}