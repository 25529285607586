import httpService from "@/request"

// 查询所有的违禁关键字
export function getForbiddenList(params) {
	return httpService({
		url: `/user/prohibitedKeywords/list`,
		method: 'get',
		params: params,
	})
}

// 添加违禁字
export function addForbidden(params) {
	return httpService({
		url: `/user/prohibitedKeywords/insert`,
		method: 'post',
		data: params,
	})
}

// 删除违禁字
export function deleteForbidden(params) {
	return httpService({
		url: `/user/prohibitedKeywords/delete`,
		method: 'post',
		data: params,
	})
}

// 根据id查询违禁字
export function findForbidden(params) {
	return httpService({
		url: `/user/prohibitedKeywords/findById`,
		method: 'get',
		params: params,
	})
}

// 修改违禁字
export function updateForbidden(params) {
	return httpService({
		url: `/user/prohibitedKeywords/update`,
		method: 'post',
		data: params,
	})
}